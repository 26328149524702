<template>
  <section class="form">
    <FeaturedColPartForm />
    <article class="form-content">
      <HeaderComponentPartForm
        title="Reestablece tu contraseña"
        description="Asegúrate que sea segura"
        type="password"
      />
      <NewPasswordForm />
    </article>
  </section>
</template>
<script>
import NewPasswordForm from '@/modules/auth/views/new-password/forms/NewPasswordForm';
import {
  FeaturedColPartForm,
  HeaderComponentPartForm,
} from '@/modules/auth/components/form-parts';
import { onBeforeMount } from 'vue';
import useAuthentication from '@/modules/auth/composables/authentication/useAuthentication';

export default {
  components: {
    FeaturedColPartForm,
    HeaderComponentPartForm,
    NewPasswordForm,
  },
  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('reset-password');
    });
  },
};
</script>
